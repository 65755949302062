import React from "react";
import {
  Box,
  chakra,
  Text,
  useColorModeValue,
  Stack,
  Button,
  Icon,
  Flex,
  useBreakpoint,
} from "@chakra-ui/react";
import Link from "next/link";
import LazyLoad, { lazyload } from "react-lazyload";
import HeroScreenshot from "../../images/hero-screenshot.png";
import HeroScreenshotDark from "../../images/hero-screenshot-dark.png";
import PickModelScreenshot from "../../images/mobile-screenshots/pick-model.png";
import PickModelScreenshotDark from "../../images/mobile-screenshots/pick-model-dark.png";
import SearchScreenshot from "../../images/mobile-screenshots/search.png";
import SearchScreenshotDark from "../../images/mobile-screenshots/search-dark.png";
import PriceScreenshot from "../../images/mobile-screenshots/price.png";
import PriceScreenshotDark from "../../images/mobile-screenshots/price-dark.png";
import DesktopPickModelScreenshot from "../../images/desktop-screenshots/pick-model.png";
import DesktopPickModelScreenshotDark from "../../images/desktop-screenshots/pick-model-dark.png";
import DesktopSearchScreenshot from "../../images/desktop-screenshots/search.png";
import DesktopSearchScreenshotDark from "../../images/desktop-screenshots/search-dark.png";
import DesktopPriceScreenshot from "../../images/desktop-screenshots/price.png";
import DesktopPriceScreenshotDark from "../../images/desktop-screenshots/price-dark.png";

import Sticker from "../../images/hero-sticker.png";
import Image from "next/image";

export function Hero() {
  const stickerClass = useColorModeValue("", "sticker-dark");
  const heroScreenshot = useColorModeValue(HeroScreenshot, HeroScreenshotDark);

  const mobileScreenshots = useColorModeValue(
    [SearchScreenshot, PickModelScreenshot, PriceScreenshot],
    [SearchScreenshotDark, PickModelScreenshotDark, PriceScreenshotDark]
  );

  const desktopScreenshots = useColorModeValue(
    [
      DesktopSearchScreenshot,
      DesktopPickModelScreenshot,
      DesktopPriceScreenshot,
    ],
    [
      DesktopSearchScreenshotDark,
      DesktopPickModelScreenshotDark,
      DesktopPriceScreenshotDark,
    ]
  );
  const currentBreakpoint = useBreakpoint("base") as string;
  const isMobile = ["base", "xs", "sm"].includes(currentBreakpoint) || false;
  const isDesktopOrTablet = !isMobile;

  let stepImageSize = { width: "250px", height: "300px" };
  if (currentBreakpoint == "base")
    stepImageSize = { width: "250px", height: "350px" };
  else if (currentBreakpoint == "sm")
    stepImageSize = { width: "300px", height: "400px" };
  else if (currentBreakpoint == "md")
    stepImageSize = { width: "600px", height: "435px" };
  else if (currentBreakpoint == "lg")
    stepImageSize = { width: "800px", height: "500px" };
  else stepImageSize = { width: "1200px", height: "761px" };

  console.log("imageStep", currentBreakpoint, stepImageSize);

  let steps = [];
  if (isMobile) {
    // For mobile device
    steps = [
      {
        description: "Search by keyword and select the desired mobile",
        image: mobileScreenshots[0],
      },
      {
        description:
          "Once navigated to a new page, select the correct model using colour, size",
        image: mobileScreenshots[1],
      },
      {
        description: "The prices from telcos will be shown below",
        image: mobileScreenshots[2],
      },
    ];
  } else {
    // For desktop device
    steps = [
      {
        description: "Search by keyword and select the desired mobile",
        image: desktopScreenshots[0],
      },
      {
        description:
          "Once navigated to a new page, select the correct model using colour, size",
        image: desktopScreenshots[1],
      },
      {
        description: "The prices from telcos will be shown below",
        image: desktopScreenshots[2],
      },
    ];
  }

  return (
    <Box px={8} py={{ base: 12, md: 24 }} mx="auto">
      <Box
        w={{ base: "full", md: 11 / 12, xl: 9 / 12 }}
        mx="auto"
        textAlign={{ base: "left", md: "center" }}
      >
        <Image
          src={Sticker}
          width={160}
          height={120}
          alt="relief"
          className={stickerClass}
        />
        <chakra.h1
          mb={6}
          mt={6}
          fontSize={{ base: "4xl", md: "6xl" }}
          fontWeight="bold"
          lineHeight="none"
          letterSpacing={{ base: "normal", md: "tight" }}
          color={useColorModeValue("gray.900", "gray.100")}
        >
          Find the best
          <Text
            display={{ base: "block" }}
            w="full"
            bgClip="text"
            // bgGradient="linear(to-r, green.200, purple.500)"
            bgSize={"400% 400%"}
            animation="gradient 5s ease infinite"
            bgGradient="linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)"
            fontWeight="extrabold"
          >
            mobile and plan
          </Text>{" "}
          deals in Singapore
        </chakra.h1>
        <chakra.p
          px={{ base: 0, lg: 24 }}
          mb={6}
          fontSize={{ base: "lg", md: "xl" }}
          color={useColorModeValue("gray.600", "gray.300")}
        >
          MobileCompare collects the latest prices and products information
          across major telco websites
          <br /> so you only need to visit{" "}
          <Link href={"/search"} passHref>
            <chakra.span
              cursor={"pointer"}
              textDecoration={"underline"}
              color="green.200"
            >
              mobilecompare.co
            </chakra.span>
          </Link>{" "}
          to find the best deal
        </chakra.p>
        <Stack
          direction={{ base: "column", sm: "row" }}
          mb={{ base: 4, md: 8 }}
          spacing={2}
          justifyContent={{ sm: "left", md: "center" }}
        >
          <Link href="/search" passHref>
            <Button
              as="a"
              variant="solid"
              bg="green.100"
              color={"black"}
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              w={{ base: "full", sm: "auto" }}
              mb={{ base: 2, sm: 0 }}
              size="lg"
              cursor="pointer"
            >
              Get Started
              <Icon boxSize={4} ml={1} viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </Icon>
            </Button>
          </Link>
        </Stack>
      </Box>

      {isDesktopOrTablet && (
        <Box
          w={{ base: "full", md: 10 / 12 }}
          mx="auto"
          mt={20}
          textAlign="center"
        >
          <Image
            src={heroScreenshot}
            {...stepImageSize}
            alt="MobileCompare website demo"
            placeholder="blur"
          />
        </Box>
      )}

      <Box
        w={{ base: "full", md: 8 / 12 }}
        mx="auto"
        mt={20}
        textAlign="center"
      >
        <chakra.h1 textAlign={"left"}>
          Why <chakra.span color="green.200">MobileCompare ?</chakra.span>
        </chakra.h1>{" "}
        <chakra.p textAlign={"left"}>
          <chakra.p fontWeight="bold">
            <s>
              Because some telco website suck and it inspired me to create
              better one.
              <chakra.p mt={12}>Just Kidding. 😛</chakra.p>
            </s>
          </chakra.p>
          <chakra.p mt={4}>
            Comparing prices is both <i>time consuming</i> and{" "}
            <i>complicated</i> due to the number of packages given by companys.{" "}
          </chakra.p>
          <chakra.p>
            So we are inspired to simplying this process by summarizing all
            price to save everyone money 💰 and time ⏱️.
          </chakra.p>
        </chakra.p>
      </Box>

      <Box
        w={{ base: "full", md: 8 / 12 }}
        mx="auto"
        mt={36}
        textAlign="center"
      >
        <chakra.h1 textAlign={"left"}>
          How to <chakra.span color="green.200">Use</chakra.span> ?
        </chakra.h1>
        <Flex
          flexDirection={{ base: "column" }}
          flexWrap="wrap"
          gap={12}
          mt={8}
        >
          {steps.map((step, index) => {
            return (
              <chakra.div
                key={index}
                display={"flex"}
                flexDirection="column"
                alignItems={"center"}
                justifyContent="center"
                mb={16}
                flex="1 1 auto"
              >
                <chakra.h2 fontWeight={"bold"} fontSize={"2xl"}>
                  Step {index + 1}
                </chakra.h2>
                <chakra.p textAlign={"center"} my={4}>
                  {step.description}
                </chakra.p>

                <LazyLoad height={stepImageSize.height}>
                  <chakra.div position="relative" {...stepImageSize}>
                    <Image
                      src={step.image}
                      layout="fill"
                      placeholder="blur"
                      objectFit="contain"
                      alt="Search mobile screenshot"
                    />
                  </chakra.div>
                </LazyLoad>
              </chakra.div>
            );
          })}
        </Flex>
      </Box>
    </Box>
  );
}
