import React from "react";
import { Hero } from "./components/Hero";
import type { NextPage } from "next";
// import NextHead from "next/head";

const Page: NextPage = () => {
  return (
    <>
      <Hero />
    </>
  );
};

export default Page;
